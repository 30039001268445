//eslint-disable-next-line
export default {
    'version': (window.X_version || process.env.REACT_APP_version),
    'is_development': (window.X_IS_DEVELOPMENT === "true" || process.env.REACT_APP_IS_DEVELOPMENT === "true" || false),
    'is_public': (window.X_IS_PUBLIC === "true" || process.env.REACT_APP_IS_PUBLIC === "true" || false),
    'defaultLanguage': 'sl-SI',
    'defaultCurrency': 'EUR',
    'gmapsKey': 'AIzaSyDVSt74clEtWe7uEYaz0NCN87mNUKKWQMo',
    'externallinks': {
        'sipassFurs': (window.X_SIPASS_URL_FURS || process.env.REACT_APP_SIPASS_URL_FURS),
        'sipassMju': (window.X_SIPASS_URL_MJU || process.env.REACT_APP_SIPASS_URL_MJU),
        'sipass': (window.X_SIPASS_URL || process.env.REACT_APP_SIPASS_URL),
    },
    'googleAnalytics': (window.X_GA || process.env.REACT_APP_GA),
    'spa': (window.X_SPA_URL || process.env.REACT_APP_SPA_URL),
    'api': {
        'private': (window.X_API_URL || process.env.REACT_APP_API_URL || 'https://api-edrazbe.dev.izstop.si')+'/private/',
        'bidder': (window.X_API_URL || process.env.REACT_APP_API_URL || 'https://api-edrazbe.dev.izstop.si')+'/bidder/',
        'download': (window.X_SPA_URL || process.env.REACT_APP_SPA_URL || 'https://edrazbe.dev.izstop.si')+'/private/download',
        'downloadPublic': (window.X_SPA_URL || process.env.REACT_APP_SPA_URL || 'https://edrazbe.dev.izstop.si')+'/public/download',
        'downloadBidder': (window.X_SPA_URL || process.env.REACT_APP_SPA_URL || 'https://edrazbe.dev.izstop.si')+'/bidder/download',
        'public': (window.X_API_URL || process.env.REACT_APP_API_URL || 'https://api-edrazbe.dev.izstop.si')+'/public/',
        'api': (window.X_API_URL || process.env.REACT_APP_API_URL || 'https://api-edrazbe.dev.izstop.si'),
        'wss': (window.X_WSS_URL || 'wss://api-edrazbe.dev.izstop.si/ws')
    },
    'system_notification_title': (window.X_system_notification_title || process.env.REACT_APP_system_notification_title),
    'system_notification_text': (window.X_system_notification_text || process.env.REACT_APP_system_notification_text),
    'frontend': (JSON.parse(window.X_FRONTEND || process.env.REACT_APP_FRONTEND)),
    'openMap': (window.X_OPEN_MAP_URL || /*'http://open-map.local.izstop.si'*/ 'https://openstreetmap.g.sodnedrazbe.si/'),
    'whichMap': (window.X_WHICH_MAP || process.env.REACT_APP_WHICH_MAP),
    'recaptchaSitekey': (window.X_RECAPTCHA_KEY || process.env.REACT_APP_RECAPTCHA_KEY),
    "2fa": (window.X_2FA === "true" || process.env.REACT_APP_2FA === "true" || false),
};
