export const SETACCEPTLANUAGE = 'SETACCEPTLANUAGE';
export const SETJWTTOKEN = 'SETJWTTOKEN';
export const SETDICTIONARY = 'SETDICTIONARY';
export const SETCOOKIECONSENT = 'SETCOOKIECONSENT';
export const INITIALIZEGA = 'INITIALIZEGA';
export const SETWSMSG = 'SETWSMSG';
export const SETTIME = 'SETTIME';
export const SETWSACTIVITY = 'SETWSACTIVITY';
export const SETORGANIZATION = "SETORGANIZATION"
export const SETADMINTAB = "SETADMINTAB"
export const SETBIDDERTAB = "SETBIDDERTAB"
export const RELOADLIST = "RELOADLIST"
export const SETBIDDERIDS = 'SETBIDDERIDS';
export const SETLOADINGFILES = 'SETLOADINGFILES';
export const SETORGANIZATIONPARAMSBE = 'SETORGANIZATIONPARAMSBE';
export const SETREFRESH2FA = 'SETREFRESH2FA';
export const SETSELECTEDLICENCE = 'SETSELECTEDLICENCE';
export const SETEMPTYHOMESALE = 'SETEMPTYHOMESALE';
export const SETEMPTYHOMEPURCHASE = 'SETEMPTYHOMEPURCHASE';
export const SETEMPTYHOMEDEFAULT = 'SETEMPTYHOMEDEFAULT';
export const SETEMPTYHOMEOFFERS = 'SETEMPTYHOMEOFFERS';