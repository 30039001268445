import * as actionTypes from '../store/actions';
import {parseJwt} from "../izUtils";
import env from '../env/src_config';

const initialState = {
    reloadList: false,
    adminTab: env.is_development ? '#groups' : '#users',
    bidderTab: '#profile',
    dictionary: {},
    cookiesConsent: [],
    initializedGA: false,
    ws: null,
    wsConnected: false,
    wsPublication: [],
    lastWsMessage: null,
    wsTime: null,
    bidderSubmitIds: null,
    organization: null,
    loadingFiles: false,
    orgParams: null,
    orgParamsBE: null,
    refresh2fa: false,
    selectedLicence: 'realestate',
    emptyHomeSale: false,
    emptyHomePurchase: false,
    emptyHomeDefault: false,
    emptyHomeOffers: false,
    options: {
        postHeaders: {
            headers: {
                "Accept": 'application/json'
            }
        }
    }
};

const reducer = (state = initialState, action) => {
    const newState = Object.assign({}, state);

    switch(action.type) {
        case actionTypes.SETACCEPTLANUAGE:
            newState.options.postHeaders.headers['Accept-Language'] = action.value;
            return newState;
        case actionTypes.SETJWTTOKEN:
            if (action.value.length !== 0) {
                newState.options.postHeaders.headers['Authorization'] = action.value;
                newState.options.parsedToken = parseJwt(action.value);
            } else {
                delete newState.options.postHeaders.headers.Authorization;
                delete newState.options.parsedToken;
            }

            return newState;
        case actionTypes.SETDICTIONARY:
            newState.dictionary = action.value;

            return newState;

        case actionTypes.SETWSACTIVITY:
            newState.wsConnected = action.value;
            console.log('set activity value: '+action.value);
            return newState;
        case actionTypes.SETCOOKIECONSENT:
            newState.cookiesConsent = action.value;

            return newState;
        case actionTypes.SETBIDDERIDS:
            newState.bidderSubmitIds = action.value;

            return newState;
        case actionTypes.INITIALIZEGA:
            newState.initializedGA = true;

            return newState;
        case actionTypes.SETTIME:
            newState.wsTime = action.value;

            return newState;
        case actionTypes.SETWSMSG:
            if (action.value.t === "publication") {
                newState.lastWsMessage = action.value;
                let newArray = [...newState.wsPublication];
                newArray.push(action.value);
                newState.wsPublication = newArray;
            } else if (action.value.t === "time") {
                newState.wsTime = action.value.unix;
            }
            return newState;
        case actionTypes.SETORGANIZATION:
            newState.organization = action.value;
            return newState;
        case actionTypes.SETADMINTAB:
            newState.adminTab = action.value;
            return newState;
        case actionTypes.SETBIDDERTAB:
            newState.bidderTab = action.value;
            return newState;
        case actionTypes.RELOADLIST:
            newState.reloadList = action.value;
            return newState;
        case actionTypes.SETLOADINGFILES:
            newState.loadingFiles = action.value;
            return newState;
        case actionTypes.SETORGANIZATIONPARAMSBE:
            newState.orgParamsBE = action.value;
            return newState;
        case actionTypes.SETREFRESH2FA:
            newState.refresh2fa = action.value;
            return newState;
        case actionTypes.SETSELECTEDLICENCE:
            newState.selectedLicence = action.value;
            return newState;
        case actionTypes.SETEMPTYHOMESALE:
            newState.emptyHomeSale = action.value;
            return newState;
        case actionTypes.SETEMPTYHOMEPURCHASE:
            newState.emptyHomePurchase = action.value;
            return newState;
        case actionTypes.SETEMPTYHOMEDEFAULT:
            newState.emptyHomeDefault = action.value;
            return newState;
        case actionTypes.SETEMPTYHOMEOFFERS:
            newState.emptyHomeOffers = action.value;
            return newState;
        default: return state;
    }
};

export default reducer;
